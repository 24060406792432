.container {
    max-width: var(--block-max-width-text);
    padding: 30px 0;
    margin: 0 auto;

    svg {
        display: block;
        width: 150px;
        margin: 0 auto;

        :global {
            .cls-1 {
                fill: #0d349d;
            }

            .cls-2 {
                fill: #0b295e;
            }

            .cls-3 {
                fill: #fff;
            }

            .cls-4 {
                fill: #1858b0;
            }

            .cls-main {
                fill: #e1e8f4;
            }

            .cls-check {
                & :local {
                    animation: check 5s ease-in-out infinite;
                }
            }

            .cls-5 {
                fill: #e1e8f4;

                &:nth-child(2) {
                    animation-delay: 1s;
                }

                &:nth-child(2n) {
                    animation-delay: 2s;
                }

                &:nth-child(4) {
                    animation-delay: 2.5s;
                }

                &:nth-child(5) {
                    animation-delay: 3s;
                }

                &:nth-child(7) {
                    animation-delay: 2.5s;
                }

                &:nth-child(8) {
                    animation-delay: 4.5s;
                }

                &:nth-child(9) {
                    animation-delay: 5s;
                }

                & :local {
                    animation: bluer linear 5s infinite;
                }
            }

            .cls-6 {
                fill: none;
                stroke: #0b295e;
                stroke-linecap: round;
                stroke-linejoin: round;
                stroke-width: 1.34px;
            }

            .cls-7 {
                fill: #e6e6e8;
            }

            .cls-8 {
                fill: #f4172b;
            }
        }
    }
}

@keyframes check {
    0% {
        opacity: 1;
    }

    50% {
        opacity: 1;
    }

    55% {
        opacity: 0;
    }

    79% {
        opacity: 0;
    }

    85% {
        opacity: 1;
    }

    100% {
        opacity: 1;
    }
}

@keyframes bluer {
    0% {
        fill: #e6e6e8;
    }

    30% {
        fill: #e6e6e8;
    }

    31% {
        fill: #0d349d;
    }

    50% {
        fill: #0d349d;
    }

    51% {
        fill: #0b295e;
    }

    70% {
        fill: #0b295e;
    }

    71% {
        fill: #e6e6e8;
    }

    100% {
        fill: #e6e6e8;
    }
}

.card h3 {
    margin-bottom: 0 !important;
}

.container {
    text-transform: uppercase;

    &.visible {
        .chart .section .bar {
            opacity: 1;
        }
    }

    .chart {
        display: flex;
        padding: 30px 10px;

        & > * {
            border-radius: 10px;
        }

        .section {
            position: relative;
            text-align: right;

            .label {
                display: flex;
                height: 100px;
                flex-direction: column;
                align-items: flex-end;
                padding-right: var(--padding-right);
                font-size: 16px;

                --padding-right: 20px;

                & > :first-child {
                    width: 150px;
                    min-height: 40px;
                    margin-bottom: 5px;
                }

                .value {
                    position: relative;
                    font-size: 20px;
                    font-weight: bold;

                    &::after {
                        position: absolute;
                        top: 0;
                        right: calc((0px - var(--padding-right)) / 2);
                        display: block;
                        width: 2px;
                        height: 70px;
                        background: rgb(24 51 151);
                        content: '';
                    }
                }
            }

            .bar {
                height: 30px;
                border-radius: 10px;
                opacity: 0;
                transition: opacity 0.33s ease-out;
                transition-delay: 0s;

                &.top {
                    background: rgb(222 56 55);
                }

                &.bottom {
                    background: rgb(43 86 170);
                    transition-delay: 0.75s;
                }
            }

            .separator {
                width: 100%;
                height: 10px;
                border-radius: 10px;
                background: rgb(21 45 134);
            }

            .downLine {
                position: absolute;
                top: 160px;
                right: 10px;
                width: 2px;
                height: 40px;
                background: rgb(21 45 134);
            }

            &.left {
                .bottom {
                    background: none;
                }

                .separator {
                    border-bottom-right-radius: 0;
                    border-top-right-radius: 0;
                }
            }

            &.right {
                .top {
                    background: none;
                }

                .separator {
                    border-bottom-left-radius: 0;
                    border-top-left-radius: 0;
                }
            }
        }
    }

    .result {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 14px 12px 12px;
        border-radius: 10px;
        background-color: rgb(43 86 170);
        color: white;
        gap: 1em;
        line-height: 1;
        transition: color 0.33s ease-out, background-color 0.33s ease-out;

        &:hover {
            background-color: #fff;
            color: rgb(43 86 170);
        }
    }
}

.container {
    max-width: var(--block-max-width-text);
    padding: 20px 0;
    margin: 0 auto;

    svg {
        display: block;
        width: 80%;
        margin: 0 auto;

        .roxanne,
        .derek {
            .character,
            .bubble {
                transform-box: fill-box;
                transform-origin: center;
                transition: transform 0.1s ease-in-out;
            }

            .character .animRoot {
                animation: slow-float 3s ease-in-out infinite alternate;
            }

            &:hover {
                .character {
                    transform: scale(1.05);
                }

                .bubble {
                    transform: scale(1.2);
                }
            }
        }

        .derek .character .animRoot {
            animation-delay: 600ms;
        }

        :global {
            .cls-1 {
                fill: #88a8d7;
            }

            .cls-2,
            .cls-3 {
                stroke-width: 2.02px;
            }

            .cls-2,
            .cls-3,
            .cls-4,
            .cls-5,
            .cls-6,
            .cls-7,
            .cls-8,
            .cls-9 {
                fill: none;
            }

            .cls-2,
            .cls-3,
            .cls-6,
            .cls-7,
            .cls-9 {
                stroke-linecap: round;
                stroke-linejoin: round;
            }

            .cls-2,
            .cls-7 {
                stroke: #1858b0;
            }

            .cls-3 {
                stroke: #f4172b;
            }

            .cls-10 {
                fill: url("#Nouveau_motif");
                opacity: 0.5;
            }

            .cls-11 {
                fill: #fff;
            }

            .cls-12 {
                fill: #1858b0;
            }

            .cls-13 {
                clip-path: url("#clippath-1");
            }

            .cls-5,
            .cls-6 {
                stroke: #071a3d;
            }

            .cls-5,
            .cls-6,
            .cls-7 {
                stroke-width: 3.79px;
            }

            .cls-5,
            .cls-8 {
                stroke-miterlimit: 10;
            }

            .cls-14 {
                fill: #071a3d;
            }

            .cls-15 {
                fill: #f41c2c;
            }

            .cls-16 {
                fill: #fde6e6;
            }

            .cls-8 {
                opacity: 0.75;
                stroke: #e1e8f4;
                stroke-width: 0.25px;
            }

            .cls-17 {
                clip-path: url("#clippath");
            }

            .cls-18 {
                fill: #8b0913;
            }

            .cls-19 {
                fill: #1245a6;
            }

            .cls-9 {
                stroke: #6a96cc;
                stroke-width: 2.71px;
            }

            .cls-20 {
                fill: #c01220;
            }
        }
    }
}

@keyframes slow-float {
    0% {
        transform: translateY(0);
    }

    50% {
        transform: translateY(-13px);
    }

    100% {
        transform: translateY(0);
    }
}

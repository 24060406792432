.container {
    max-width: var(--block-max-width-text);
    padding: 0 var(--spacing);
    margin: 0 auto;

    &.active {
        svg {
            .pie {
                opacity: 1;
            }
        }
    }

    svg {
        width: 100%;
        height: auto;

        .pie {
            opacity: 0;
            transition: opacity 0.6s ease-out;
            transition-delay: 0.3s;
        }
    }
}

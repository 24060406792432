.container {
    max-width: var(--block-max-width-text);
    padding: 20px 0;
    margin: 0 auto;

    svg {
        display: block;
        width: 200px;
        margin: 0 auto;

        .topCoin {
            animation: coin-sway 1s ease-in-out infinite alternate;
            transform-box: fill-box;
            transform-origin: center;
        }

        :global {
            .cls-1 {
                fill: #2f6eb8;
            }

            .cls-2 {
                fill: #1858b0;
            }

            .cls-3 {
                fill: #071a3d;
            }

            .cls-4 {
                fill: #f4172b;
            }

            .cls-5 {
                fill: #4d83c4;
            }
        }
    }
}

@keyframes coin-sway {
    0% {
        transform: rotate(-10deg);
    }

    100% {
        transform: rotate(10deg);
    }
}

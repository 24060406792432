.container {
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 10px 0;
    background-color: rgb(224 57 56);
    color: rgb(253 249 249);

    .value {
        display: flex;
        flex-direction: row;
        align-items: center;
        font-family: Maax, sans-serif;
        font-size: 16px;
        font-weight: 500;
        text-transform: uppercase;
        transform: translateY(0.1em);

        &:not(:last-child) {
            margin-right: 0.7em;
        }

        .separator {
            display: inline-block;
            width: auto;
            height: 10px;
            margin-left: 0.7em;
            animation: crazy-slider 1s ease-in-out infinite alternate;
        }

        &:last-child {
            .separator {
                display: none;
            }
        }
    }
}

@keyframes crazy-slider {
    0% {
      transform: translateY(-2px);
    }

    100% {
      transform: translateY(3px);
    }
}

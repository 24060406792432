.container {
    max-width: var(--block-max-width-text);
    padding: 0 var(--spacing);
    margin: 0 auto;

    &.active {
        svg {
            .pie {
                opacity: 1;
            }
        }
    }

    svg {
        width: 100%;
        height: auto;

        .pie {
            opacity: 0;
            transition: opacity 0.6s ease-out;
            transition-delay: 0.3s;
        }

        :global {
            .cls-9,
            .cls-7 {
                stroke: #4356ab;
            }

            .cls-9,
            .cls-7,
            .cls-8 {
                fill: none;
                stroke-linecap: round;
                stroke-linejoin: round;
            }

            .cls-9,
            .cls-8 {
                stroke-width: 2px;
            }

            .cls-10 {
                fill: #4d83c4;
            }

            .cls-11 {
                fill: #1245a6;
            }

            .cls-12 {
                fill: #fffdfd;
            }
        }
    }
}

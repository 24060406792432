.container {
    max-width: var(--block-max-width-text);
    padding: 0 var(--spacing);
    margin: 0 auto;

    .card {
        padding: 15px 10px;
        border: 1.5px solid;
        border-color: currentcolor;
        border-radius: 12px;
        background-color: #e1e8f4;
        font-family: Maax, sans-serif;
        font-size: 18px;
        font-weight: 500;

        h3 {
            padding: 0 10px 10px;
            border-bottom: 3px solid currentcolor;
            margin: 0 0 1em;
            font-size: 22px;
            text-align: center;
            text-transform: uppercase;
        }
    }
}
